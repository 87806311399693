import React, { Component } from 'react'

export default class NewsItem extends Component {
  render() {
    let {title, description, imageUrl, newsUrl, author, publishDate, source} = this.props;
    return (
      <div className='container my-3'>
        <div className="card position-relative">
          <img className="card-img-top" src={imageUrl ? imageUrl : "https://www.aputf.org/wp-content/uploads/2015/06/default-placeholder1-1024x1024-570x321.png"} alt="Card image cap" />
          <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white'>{source}</span>
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{description}</p>
            <p className="card-text">
              <small className="text-muted">By <b>{author ? author.charAt(0).toUpperCase() + author.slice(1) : "unknown"}</b> on <b>{new Date(publishDate).toGMTString()}</b></small>
            </p>
            <a rel='noreferrer' href={newsUrl} target='_blank' className="btn btn-sm btn-dark">Read More..</a>
          </div>
        </div>
      </div>
    )
  }
}
