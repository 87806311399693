import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Navbar extends Component {
  render() {
    // Destructure the props for easier use
    const { title, aboutText, mode, projectTitle, toggleMode } = this.props;

    return (
      <div className="container">
        <nav className={`navbar navbar-expand-lg navbar-${mode} bg-${mode}`}>
          <Link className="navbar-brand" to="/">
            <img src={`./logo512.png`} width="50" height="40" className="d-inline-block align-top mt-2" alt="TextPlay! logo" />
            <p className="ml-2 d-inline-block">
              <span className="font-weight-bold d-block">{projectTitle}</span>
              <span className="text-danger small d-block">Live News</span>
            </p>
          </Link>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto mr-2">
              <li className="nav-item active"><Link className={`nav-link text-${mode === 'light' ? 'dark' : 'light'}`} to="/">Home <span className="sr-only">(current)</span></Link></li>
              <li className="nav-item active"><Link className={`nav-link text-${mode === 'light' ? 'dark' : 'light'}`} to="/business">Business</Link></li>
              <li className="nav-item active"><Link className={`nav-link text-${mode === 'light' ? 'dark' : 'light'}`} to="/entertainment">Entertainment</Link></li>
              <li className="nav-item active"><Link className={`nav-link text-${mode === 'light' ? 'dark' : 'light'}`} to="/general">General</Link></li>
              <li className="nav-item active"><Link className={`nav-link text-${mode === 'light' ? 'dark' : 'light'}`} to="/health">Health</Link></li>
              <li className="nav-item active"><Link className={`nav-link text-${mode === 'light' ? 'dark' : 'light'}`} to="/science">Science</Link></li>
              <li className="nav-item active"><Link className={`nav-link text-${mode === 'light' ? 'dark' : 'light'}`} to="/sports">Sports</Link></li>
              <li className="nav-item active"><Link className={`nav-link text-${mode === 'light' ? 'dark' : 'light'}`} to="/technology">Technology</Link></li>
            </ul>
            <span className={`mr-2 text-${mode === 'light' ? 'dark' : 'light'}`}><i className="fa fa-sun-o" aria-hidden="true"></i></span>
            <div className={`custom-control custom-switch text-${mode === 'light' ? 'dark' : 'light'}`}>
              <input type="checkbox" onClick={toggleMode} className="custom-control-input" id="customSwitch1" />
              <label className="custom-control-label text-right" htmlFor="customSwitch1"><i className="fa fa-moon-o" aria-hidden="true"></i></label>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

Navbar.propTypes = {
  projectTitle: PropTypes.string.isRequired,
  aboutText: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  toggleMode: PropTypes.func.isRequired,
};

Navbar.defaultProps = {
  projectTitle: 'NewsPlay!',
  aboutText: 'About',
};

export default Navbar;
