import React, { Component } from 'react';
import NewsItem from './NewsItem';
import Spinner from './Spinner';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

export default class News extends Component {

  static defaultProps = {
    country: 'in',
    pageSize: 12,
    category: 'general'
  }

  static propTypes = {
    country: PropTypes.string,
    pageSize: PropTypes.number,
    category: PropTypes.string,
  setProgress: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      loading: true,
      page: 1,
      totalResults: 0,
    }
  }

  async updateNews(pageNo) {
    try {
      this.props.setProgress(10);
      const url = `https://newsapi.org/v2/top-headlines?country=in&category=${this.props.category}&apiKey=${this.props.apiKey}&page=${this.state.page}&pageSize=${this.props.pageSize}`;
      this.setState({ loading: true });
      let data = await fetch(url, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
        agent: false,
      });
      this.props.setProgress(30);
      let parseData = await data.json();
      this.props.setProgress(70);
      this.setState({
        articles: parseData.articles,
        totalResults: parseData.totalResults,
        loading: false,
      })
      
      this.props.setProgress(100);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async componentDidMount() {
    this.updateNews();
  }

  handlePrevClick = async () => {
    this.setState({
      page: this.state.page - 1,
    });
    this.updateNews();
  }

  handleNextClick = async () => {
    this.setState({
      page: this.state.page + 1,
    });
    this.updateNews();
  }

  fetchMoreData = async () => {
    this.setState({
      page: this.state.page + 1,
    });
    try {
      const url = `https://newsapi.org/v2/top-headlines?country=in&category=${this.props.category}&apiKey=${this.props.apiKey}&page=${this.state.page}&pageSize=${this.props.pageSize}`;
      this.setState({ loading: true });
      let data = await fetch(url, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
        agent: false,
      });
      let parseData = await data.json();
      this.setState({
        articles: this.state.articles.concat(parseData.articles),
        totalResults: parseData.totalResults,
        loading: false,
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  render() {
    return (
      <div className='container my-3'>
        <h2 className='text-center'>NewsPlay! - Top {this.props.category.charAt(0).toUpperCase() + this.props.category.slice(1)} Headlines</h2>
        <InfiniteScroll
          dataLength={this.state.articles ? this.state.articles.length : 0}
          next={this.fetchMoreData}
          style={{ lexDirection: 'column-reverse' }}
          hasMore={this.state.articles && this.state.totalResults && this.state.articles.length !== this.state.totalResults}
          loader={<Spinner/>}
          scrollableTarget="scrollableDiv"
        >
          <div className="container">
            <div className="row">
                {this.state.articles &&
                  this.state.articles.map((element) => (
                    <div className="col-md-4" key={element.url}>
                      <NewsItem
                        title={element.title ? element.title.slice(0, 35) : ''}
                        description={element.description ? element.description.slice(0, 120) : ''}
                        imageUrl={element.urlToImage}
                        newsUrl={element.url}
                        author={element.author}
                        publishDate={element.publishedAt}
                        source={element.source.name}
                      />
                    </div>
                ))}
              {/* {!this.state.loading &&
                this.state.articles &&
                this.state.articles.map((element) => (
                  <div className="col-md-4" key={element.url}>
                    <NewsItem
                      title={element.title ? element.title.slice(0, 35) : ''}
                      description={element.description ? element.description.slice(0, 120) : ''}
                      imageUrl={element.urlToImage}
                      newsUrl={element.url}
                      author={element.author}
                      publishDate={element.publishedAt}
                      source={element.source.name}
                    />
                  </div>
                ))} */}
            </div>
          </div>
        </InfiniteScroll>
        {/* <div className="container">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className={`page-item ${this.state.page <= 1 ? 'disabled' : ''}`}>
                <a className="page-link" href="#" tabIndex="-1" onClick={this.handlePrevClick}>
                  &larr; Previous
                </a>
              </li>
              <li className="page-item"><a className="page-link" href="#">1</a></li>
              <li className="page-item"><a className="page-link" href="#">2</a></li>
              <li className="page-item"><a className="page-link" href="#">3</a></li>
              <li className={`page-item ${this.state.page + 1 > Math.ceil(this.state.totalResults / this.props.pageSize) ? 'disabled' : ''}`}>
                <a rel='noreferrer' className="page-link" href="#" onClick={this.handleNextClick}>Next &rarr; </a>
              </li>
            </ul>
          </nav>
        </div> */}
      </div>
    );
  }
}
