import React, { Component } from 'react';
import Navbar from './components/Navbar';
import News from './components/News';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';

class App extends Component {
  constructor() {
    super();
    this.state = {
      mode: 'light',
      projectTitle: "NewsPlay!",
      currentYear: '',
      pageSize: 12,
    //   apiKey: process.env.REACT_APP_NEWS_AP,
      apiKey: "607f49b2814248a19b7733de6c1f8dcd",
      progress: 0,
    };
  }

  toggleMode = () => {
    const { mode } = this.state;
    if (mode === 'light') {
      this.setState({ mode: 'dark' });
      document.body.style.backgroundColor = '#042743';
      this.showAlert('Dark mode has been enabled', 'success');
    } else {
      this.setState({ mode: 'light' });
      document.body.style.backgroundColor = 'white';
      this.showAlert('Light mode has been enabled', 'success');
    }
  };

  showAlert = (message, type) => {
    // Implement your alert logic here
    console.log(`${type}: ${message}`);
  };

  componentDidMount() {
    this.setState({ currentYear: new Date().getFullYear() });
  }

  setProgress = (progress) => {
    this.setState({
      progress: progress
    });
  }  

  render() {
    const { mode, projectTitle, currentYear, pageSize, progress, apiKey} = this.state;
    console.log(apiKey);
    return (
        <>
            <Router>
                <div className='container'>
                    <Navbar title="NewsPlay!" mode={mode} toggleMode={this.toggleMode} key={new Date()} />
                    <main id="main" className="site-main main">
                        {/*  BLOCK SECTION START */}
                        <section className="block-section">
                            <div className="container my-3">
                            <LoadingBar height={3} color='#f11946' progress={progress} />
                            <Switch>
                                <Route exact path="/"><News setProgress={this.setProgress} apiKey={apiKey}  key="all" pageSize={pageSize} country="in" category=""/></Route>
                                <Route exact path="/business"><News setProgress={this.setProgress} apiKey={apiKey}  key="business" pageSize={pageSize} country="in" category="business"/></Route>
                                <Route exact path="/entertainment"><News setProgress={this.setProgress} apiKey={apiKey}  key="entertainment" pageSize={pageSize} country="in" category="entertainment"/></Route>
                                <Route exact path="/general"><News setProgress={this.setProgress} apiKey={apiKey}  key="general" pageSize={pageSize} country="in" category="general"/></Route>
                                <Route exact path="/health"><News setProgress={this.setProgress} apiKey={apiKey}  key="health" pageSize={pageSize} country="in" category="health"/></Route>
                                <Route exact path="/science"><News setProgress={this.setProgress} apiKey={apiKey}  key="science" pageSize={pageSize} country="in" category="science"/></Route>
                                <Route exact path="/sports"><News setProgress={this.setProgress} apiKey={apiKey}  key="sports" pageSize={pageSize} country="in" category="sports"/></Route>
                                <Route exact path="/technology"><News setProgress={this.setProgress} apiKey={apiKey}  key="technology" pageSize={pageSize} country="in" category="technology"/></Route>
                            </Switch>
                            </div>
                        </section>
                        {/*  BLOCK SECTION END */}
                        {/*  BLOCK 1 SECTION START */}

                        <section className="block-section1">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Let's Play with Your Text!</h4>
                                    <p className="text-muted">Explore a world of text transformation with our comprehensive services. whether you need precise capitalization, intricate modification, polished formatting, strategic manipulation, or seamless concatenation, we've got you covered. elevate your content to new heights with our expert text services, tailored to meet your specific needs. transform the way your text speaks and captivates your audience with our professional touch.</p>
                                </div>
                                <div className="col-md-6 col-sm-12 how-img"> {/*  Added col-sm-12 for mobile responsiveness */}
                                    <img src={`./front-end/images/png/section2.png`} className="rounded-circle img-fluid" alt="world of text transformation" />
                                </div>
                            </div>
                        </div>
                        </section>
                        {/*  BLOCK 1 SECTION END */}
                        {/*  -------------------------------------**** --- */}
                        {/*  BLOCK 2 SECTION START */}
                        <section className="block2-section">
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-12">
                                <div className="container-fluid px-3 px-sm-5 my-5 text-center">
                                    <h2 className="text-dark">What Our Clients Say</h2>
                                </div>
                                <div id="customers-testimonials" className="owl-carousel">
                                    {/*  TESTIMONIAL 1 */}
                                    <div className="item shadow-lg">
                                        <div className="p-4 bg-white rounded">
                                            <img src={`./front-end/images/png/earth.png`} alt="review1" />
                                            <div className="commo pt-3">
                                                <img src={`./front-end/images/png/commo6.png`} alt='review1 comma ' />
                                            </div>
                                            <p className="mb-4 text-muted">Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
                                            <hr/>
                                            <div className="d-flex align-items-center">
                                                <div className="author-img mr-3">
                                                    <img src={`./front-end/images/png/user1.png`} alt="review1 client" />
                                                </div>
                                                <div>
                                                    <h6 className="m-0 float-left font-weight-bold text-warning">Kevin Rich</h6>
                                                    <p className="m-0 small font-medium text-muted">Web Developer</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  END OF TESTIMONIAL 1 */}
                                    {/*  TESTIMONIAL 2 */}
                                    <div className="item shadow-lg">
                                        <div className="p-4 bg-white rounded">
                                            <img src={`./front-end/images/png/cod-lab.png`} alt="review2" />
                                            <div className="commo pt-3">
                                                <img src={`./front-end/images/png/commo6.png`} alt="review2 comma" />
                                            </div>
                                            <p className="mb-4 text-muted">Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
                                            <hr/>
                                            <div className="d-flex align-items-center">
                                                <div className="author-img mr-3">
                                                    <img src={`./front-end/images/png/user2.png`} alt="review2 client" />
                                                </div>
                                                <div>
                                                    <h6 className="m-0 float-left font-weight-bold text-warning">Ruth Anthony</h6>
                                                    <p className="m-0 small font-medium text-muted">Web Developer</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  END OF TESTIMONIAL 2 */}
                                    {/*  TESTIMONIAL 3 */}
                                    <div className="item shadow-lg">
                                        <div className="p-4 bg-white rounded">
                                            <img src={`./front-end/images/png/tvit.png`} alt="review3" />
                                            <div className="commo pt-3">
                                                <img src={`./front-end/images/png/commo6.png`} alt="review3 comma" />
                                            </div>
                                            <p className="mb-4 text-muted">Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
                                            <hr/>
                                            <div className="d-flex align-items-center">
                                                <div className="author-img mr-3">
                                                    <img src={`./front-end/images/png/user3.png`} alt="review3 client" />
                                                </div>
                                                <div>
                                                    <h6 className="m-0 float-left font-weight-bold text-warning">Brian Buie</h6>
                                                    <p className="m-0 small font-medium text-muted">Web Developer</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  END OF TESTIMONIAL 3 */}
                                    {/*  TESTIMONIAL 4 */}
                                    <div className="item shadow-lg">
                                        <div className="p-4 bg-white rounded">
                                            <img src={`./front-end/images/png/lighting.png`} alt="review4" />
                                            <div className="commo pt-3">
                                                <img src={`./front-end/images/png/commo6.png`} alt="review4 comma" />
                                            </div>
                                            <p className="mb-4 text-muted">Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
                                            <hr/>
                                            <div className="d-flex align-items-center">
                                                <div className="author-img mr-3">
                                                    <img src={`./front-end/images/png/user4.png`} alt="review4 client" />
                                                </div>
                                                <div>
                                                    <h6 className="m-0 float-left font-weight-bold text-warning">Jeanna Mendoza</h6>
                                                    <p className="m-0 small font-medium text-muted">Web Developer</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  END OF TESTIMONIAL 4 */}
                                </div>
                            </div>
                            </div>
                        </div>
                        </section>
                        {/*  BLOCK 2 SECTION END */}
                        {/*  -------------------------------------**** --- */}
                    </main>

                    {/* FOOTER SECTION START */}
                    <footer className="footer">
                        <div className="container">                
                            <div className="row">
                                <div className="col-md-3 col-sm-6">
                                    <Link to="/" className="d-flex align-items-center text-dark text-decoration-none">
                                        <img src={`./logo512.png`} width="50" height="40" className="d-inline-block align-top" alt="TextPlay! logo" />
                                        <h4 className="ml-2 mb-0">{projectTitle}</h4>
                                    </Link>
                                    <p className="text-muted">Let's Play with Your Text: Explore the Possibilities of <span className='text-dark'>{projectTitle}</span>. Unleashing Creativity in Content Transformation</p>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <h4>GET IN TOUCH</h4>
                                    <ul className="list-unstyled">
                                        <li><i className="fa fa-phone mr-2"></i> +919773424748</li>
                                        <li><i className="fa fa-envelope mr-2"></i> info@textplay.in</li>
                                    </ul>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <h4>SERVICES</h4>
                                    <ul className="list-style">
                                        <li>CAPITALIZATION</li>
                                        <li>MODIFICATION</li>
                                        <li>FORMATTING</li>
                                        <li>MANIPULATION</li>
                                        <li>CONCATENATION</li>
                                    </ul>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <h4>COMPANY</h4>
                                    <ul className="list-style">
                                        <li>ABOUT US</li>
                                        <li>CONTACT US</li>
                                        <li className="text-muted">ORDER NOW <span className="badge badge-primary badge-pill">Coming soon</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-6 col-md-6">
                                    <ul id="menu-seller-footer" className="nav sub-nav">
                                        <li className="nav-item">
                                            <Link className="nav-link text-dark" to="/">Help Center</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link text-dark" to="/">Terms of Service</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link text-dark" to="/">Licenses</Link>
                                        </li>
                                    </ul>
                                </div>
                                
                                <div className="col-sm-6 col-md-6">
                                    <p className="hidden-sm-down d-none d-lg-block mt-2 float-right">© Copyright <span>{currentYear}</span> <Link to="/" className="text-dark">{projectTitle}</Link> All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </footer>

                    {/* FOOTER SECTION END */}
                    {/* -------------------------------------**** --- */}
                </div>
            </Router>
        </>
    );
  }
}

export default App;
